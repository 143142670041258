<route>
  {
    "name": "verify",
    "meta": {
      "title": "Verify",
      "auth": false
    }
  }
</route>

<template>
  <div v-if="!hasVerifyToken" class="flex-column flex-grow">
    <ui-link @click="goToBackPage">
      <img src="@/assets/images/BackArrow.svg" alt="back-arrow" class="mt-8" />
    </ui-link>
    <div class="wrapper">
      <div class="relative">
        <h1 class="title">{{ $t('verify.verify_your_e_mail') }}</h1>
        <div class="description">
          {{ $t('verify.please_enter_the_digit') }}
          <a>{{ email }}</a>
        </div>
        <div :class="['code-block', { error: hasError }]">
          <code-input
            :loading="false"
            class="mb-12"
            :fields="5"
            :fieldWidth="32"
            :fieldHeight="38"
            @complete="onComplete"
            @change="onChange"
          />
          <div v-if="hasError" class="code-message">
            {{ $t('verify.verification_code_is') }}
          </div>
        </div>
      </div>
    </div>
    <div class="description">
      <span v-if="!isWaitingResend">
        {{ $t('verify.didn_t_get_the_code') }}
        <ui-link @click="resendVerify">{{ $t('verify.resend_code') }}</ui-link>
      </span>
      <span v-else>
        <span class="text1">{{ $t('verify.resend_code_in') }}</span>
        {{ this.counterMinutes | alwaysTwoDigits }}:{{ this.counterSeconds | alwaysTwoDigits }}
      </span>
    </div>
  </div>
  <div v-else>{{ $t('verify.waiting_for_redirect') }}</div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import feathersClient, { authManagementClient } from '@/plugins/feathersClient';
import CodeInput from 'vue-verification-code-input';
import UiLink from '../components/ui/UiLink';
import mapFields from '../utils/mapFields';
import { setCookie, getCookie } from '../utils/cookie';

export default {
  layout: 'auth',
  name: 'verify',
  components: {
    CodeInput,
    UiLink
  },
  data() {
    return {
      hasVerifyToken: false,
      isWaitingResend: false,
      // Time in minutes
      resendMinutes: 2,
      counterMinutes: null,
      counterSeconds: null,
      hasError: false
    };
  },
  computed: {
    ...mapFields({
      accessToken: 'auth.user.accessToken',
      email: 'auth.user.email'
    })
  },
  async created() {
    feathersClient.service('users').on('patched', (data) => {
      const { isVerified } = data;

      if (isVerified) {
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'verify_email_complete');
        this.$router.push({ path: '/quick-note' });
      }
    });

    // Try get verfiy token (from email's link)
    const { token } = this.$route.query;
    if (token) {
      this.hasVerifyToken = true;
      this.processLongToken(token);
    } else {
      // Cover page refresh for resend mechanism
      const availabilityCookie = getCookie('resendNotAvailable');
      const availabilityTimestamp = Number.parseInt(availabilityCookie, 10);
      if (availabilityTimestamp) {
        const differenceDate = new Date(availabilityTimestamp - new Date());
        this.counterMinutes = differenceDate.getMinutes();
        this.counterSeconds = differenceDate.getSeconds();
        this.intervalId = setInterval(this.countdown, 1000);
        this.isWaitingResend = true;
      }
    }
  },
  methods: {
    onChange() {
      this.hasError = false;
    },
    async onComplete(code) {
      try {
        await authManagementClient.verifySignupShort(code, {
          email: this.email
        });
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'verify_email_complete');
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'verify_email_shortcode_complete');
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'userParams', {
          isEmailVerifiedWithShortcode: true
        });
        // will be redirected on socket event (handler in created hook)
      } catch (err) {
        this.hasError = true;

        if (err.name === 'TooManyRequests') {
          this.$notify.error('Blocked! Try in a minute.');
        } else if (err.message !== 'Token incorrect!') {
          console.error(err);
          // this.$notify.parseFeathersErrors(err);
        }
      }
    },
    async goToBackPage() {
      try {
        await this.$store.dispatch('auth/logout');
      } catch (error) {
        localStorage.removeItem('feathers-jwt');
      }
      this.$router.push({ name: 'login' });
    },
    async processLongToken(token) {
      try {
        await authManagementClient.verifySignupLong(token);
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'userParams', {
          isEmailVerifiedWithLong: true
        });

        if (localStorage['feathers-jwt']) {
          global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'verify_email_complete');
          this.$router.push({ path: '/quick-note' });
        } else {
          global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'verify_email_complete');
          this.$notify.success('User verified! Please Log In!');
          this.$router.push({ name: 'login' });
        }
      } catch (err) {
        if (err.message === 'Token incorrect!') {
          // this.$notify.parseFeathersErrors(err);
          this.hasVerifyToken = false;

          // This is flow, when person already verified (or try to modify longToken)
          // So we redirect him to /login
          this.$router.push({ name: 'login' });
        }
      }
    },
    async resendVerify() {
      this.isWaitingResend = true;
      this.startResendCounter();

      try {
        await authManagementClient.resendVerifySignup({
          email: this.email
        });
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'resend_verification_code');
      } catch (err) {
        this.$notify.parseFeathersErrors(err);
      }
    },
    startResendCounter() {
      this.counterMinutes = this.resendMinutes;
      this.counterSeconds = 0;
      this.setResendCookie();
      this.intervalId = setInterval(this.countdown, 1000);
    },
    countdown() {
      if (this.counterSeconds === 0) {
        if (this.counterMinutes === 0) {
          this.isWaitingResend = false;
          clearInterval(this.intervalId);
        } else {
          this.counterMinutes -= 1;
          this.counterSeconds = 59;
        }
      } else {
        this.counterSeconds -= 1;
      }
    },
    setResendCookie() {
      const date = new Date();
      date.setTime(date.getTime() + 2 * 60 * 1000);
      setCookie('resendNotAvailable', date.getTime(), { expires: date });
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
.wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.mb {
  margin-bottom: 16px;
}
.content {
  position: relative;
  margin-top: 60px;
}
.title {
  margin-bottom: 24px;
  text-align: center;
}
.description {
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  color: #383838;
}
</style>

<style lang="scss">
.code-block {
  margin: auto;
  height: 214px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // override component's styles
  .react-code-input-container {
    width: 208px !important;

    .react-code-input {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      input {
        padding: 0px;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        border-radius: unset !important;
        border-bottom: 2px solid #d9d9d9;
        font-family: 'Rubik';
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #383838;
        appearance: none;
        outline: none;
      }

      input:focus {
        border: 2px solid #d9d9d9;
        caret-color: #383838;
      }
    }
  }

  &.error {
    input {
      border-color: red !important;
    }

    .code-message {
      color: red;
    }
  }
}
</style>
